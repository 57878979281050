import { FINANCES_DASHBOARD,
  FINANCES_REGISTRE_CAISSE,
  FINANCES_TRESORERIE,
  FINANCES_COMMISSION_APPORTEUR
 } from "../../core/constants/layout";
import { useLayoutContext } from "../../core/context/layout-context";
import Chart from "../dashboard/components/chart";
import RegistreCaisses from "./components/registre-caisses";
import Tresorerie from "./components/tresorerie";
import CommissionsApporteurs from "./components/comissions-apporteurs";

export default function FinancePage() {
  const { selectChildren } = useLayoutContext();

  const ChildrenCFinance = () => {
    switch (selectChildren) {
      case FINANCES_DASHBOARD:
        return <Chart />;
      case FINANCES_REGISTRE_CAISSE:
        return <RegistreCaisses />;
      case FINANCES_TRESORERIE:
        return <Tresorerie />;  
      case FINANCES_COMMISSION_APPORTEUR:
          return <CommissionsApporteurs />;  

      default:
        return <Chart />;
    }
  };
  return (
    <div className="xl:px-4 pt-14 h-full bg-white overflow-auto">
      {ChildrenCFinance()}
    </div>
  );
}
