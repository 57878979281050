import {
  EyeIcon,
  PencilSquareIcon,
  RectangleGroupIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import React, { Fragment } from "react";
import {
  CAMPAGNE_NEW,
  CAMPAGNE_NEW_TEMPLATE,
} from "../../../core/constants/layout";
import { useNavigate } from "react-router-dom";

const templates = [
  {
    name: "message_diffusion",
    createdAt: "05-May-2024 02:04",
    id: "443875588990650",
    category: "UTILITY",
    status: "pending",
    language: "fr",
  },
  {
    name: "message_diffusion",
    createdAt: "05-May-2024 02:04",
    id: "443875588990650",
    category: "MARKETING",
    status: "pending",
    language: "fr",
  },
  {
    name: "message_diffusion",
    createdAt: "05-May-2024 02:04",
    id: "443875588990650",
    category: "AUTHENTICATION",
    status: "rejected",
    language: "fr",
  },
  {
    name: "message_diffusion",
    createdAt: "05-May-2024 02:04",
    id: "443875588990650",
    category: "UTILITY",
    status: "approved",
    language: "fr",
  },
];

const status = [
  { id: 1, name: "Tous" },
  { id: 2, name: "Pending" },
  { id: 3, name: "Rejected" },
  { id: 4, name: "Approved" },
];

export default function CommissionsApporteurs() {
  const navigate = useNavigate();

  const [selected, setSelected] = React.useState(status[0]);

  const handleNavigate = () => {
    navigate(`${CAMPAGNE_NEW_TEMPLATE}`);
    window.location.reload();
  };
  return (
    <>
      <div className=" max-w-7xl mx-auto ">
        <div className="flex flex-col divide-y ">
          <div className=" px-4 py-4 gap-x-4  ">
            <div className="">
              <div className="flex items-center gap-2">
                <RectangleGroupIcon className="h-6 w-6" />
                <h1>Commision des apporteurs</h1>
              </div>
              <div className="mt-8 ">
                <div className="flex flex-col divide-y border ">
                  <div className="flex items-center justify-between px-5 py-2">
                    <div className="flex gap-4">
                      <input
                        type="text"
                        className="border rounded-md h-10 px-2 w-96 font-light text-gray-600 placeholder:text-sm placeholder:text-gray-400 placeholder:font-light focus:outline-purple-600"
                        placeholder="Rechercher un template"
                      />
                      <Listbox value={selected} onChange={setSelected}>
                        {({ open }) => (
                          <>
                            <div className="relative w-full">
                              <Listbox.Button className="relative w-45  h-10 cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600 sm:text-sm sm:leading-6">
                                <span className="block truncate">
                                  {selected.name}
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {status.map((statu) => (
                                    <Listbox.Option
                                      key={statu.id}
                                      className={({ active }) =>
                                        classNames(
                                          active
                                            ? "bg-purple-600 text-white"
                                            : "text-gray-900",
                                          "relative cursor-default select-none py-2 pl-3 pr-9"
                                        )
                                      }
                                      value={statu}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span
                                            className={classNames(
                                              selected
                                                ? "font-semibold"
                                                : "font-normal",
                                              "block truncate"
                                            )}
                                          >
                                            {statu.name}
                                          </span>

                                          {selected ? (
                                            <span
                                              className={classNames(
                                                active
                                                  ? "text-white"
                                                  : "text-purple-600",
                                                "absolute inset-y-0 right-0 flex items-center pr-4"
                                              )}
                                            >
                                              <CheckIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>
                    <div className="flex items-center gap-x-2">
                      <a
                        href="#"
                        className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-lg text-sm"
                      >
                        Télècharger un template
                      </a>
                      <button
                        onClick={() => handleNavigate()}
                        className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-lg text-sm"
                      >
                        Créer un template
                      </button>
                      <a
                        href="#"
                        className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-lg text-sm"
                      >
                        Guide d'utilisation
                      </a>
                    </div>
                  </div>
                  <div className=" ">
                    <>
                      <div className="px-4 sm:px-6 lg:px-8 h-full">
                        <div className="mt-8 flow-root">
                          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8 ">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                              <table className="min-w-full divide-y divide-gray-300 h-full">
                                <thead>
                                  <tr>
                                    <th
                                      scope="col"
                                      className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                                    >
                                      NOM DU TEMPLATE
                                    </th>

                                    <th
                                      scope="col"
                                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                    >
                                      DATE DE CREATION
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                    >
                                      ID
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                    >
                                      CATE!GORIE
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                    >
                                      STATUS
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                    >
                                      LANGUE
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                    >
                                      ACTIONS
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                  {templates.map((person, i) => (
                                    <tr key={i}>
                                      <td className="whitespace-nowrap flex  gap-y-1 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                        <span className="">{person.name}</span>
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {person.createdAt}
                                      </td>

                                      <td className="whitespace-nowrap  px-3 py-4 text-sm text-gray-500">
                                        {person.id} <br />
                                      </td>
                                      <td className="whitespace-nowrap flex px-3 py-4 text-sm text-gray-500">
                                        <span>{person.category}</span>
                                      </td>
                                      <td className="whitespace-nowrap px-3 font-light py-4 text-sm text-gray-500">
                                        <span
                                          className={classNames(
                                            {
                                              "bg-yellow-100 text-yellow-600":
                                                person.status === "pending",
                                            },
                                            {
                                              "bg-green-100 text-green-600":
                                                person.status === "approved",
                                            },
                                            {
                                              "bg-red-100 text-red-600":
                                                person.status === "rejected",
                                            },
                                            " px-4 py-1 font-semibold rounded-md text-xs"
                                          )}
                                        >
                                          {person.status}
                                        </span>
                                      </td>
                                      <td className="whitespace-nowrap px-3 font-light py-4 text-sm text-gray-500">
                                        <span>{person.language}</span>
                                      </td>
                                      <td className="relative whitespace-nowrap flex gap-2 py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                        <button className="bg-blue-100 p-1 rounded-md">
                                          <EyeIcon
                                            className="h-5 w-5 text-blue-600"
                                            aria-hidden="true"
                                          />
                                        </button>
                                        <button className="bg-yellow-100 p-1 rounded-md">
                                          <PencilSquareIcon
                                            className="h-5 w-5 text-yellow-600"
                                            aria-hidden="true"
                                          />
                                        </button>
                                        <button className="bg-red-100 p-1 rounded-md">
                                          <TrashIcon
                                            className="h-5 w-5 text-red-600"
                                            aria-hidden="true"
                                          />
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
