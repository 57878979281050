import classNames from "classnames";
import {
  CAMPAGNE_CONTACT,
  CAMPAGNE_DASHBOARD,
  CAMPAGNE_FOLLOW,
  CAMPAGNE_NEW,
  CAMPAGNE_NEW_TEMPLATE,
  CAMPAGNE_TEMPLATE,
  RESERVATION_RESERVATION,
  RESERVATION_TICKET,
  RESERVATION_VOYAGE,
  RESERVATION_INCIDENT,
  RESERVATION_RECLAMATION,
  RESERVATION_REMBOURSEMENT,
  RESERVATION_RECHERCHE
} from "../../core/constants/layout";
import { useLayoutContext } from "../../core/context/layout-context";
import ChartCampagne from "./components/chart-campagne";
import Contact from "./components/contact";
import FollowCampagne from "./components/follow-campagne";
import NewCampagne from "./components/new-campagne";
import TemplateCampagne from "./components/temlate-campagne";
import CreateTemplate from "./components/template-campagne/create-template";
import RechercheReservation from "./components/recherche-reservation";

export default function ReservationsPage() {
  const { selectChildren } = useLayoutContext();

  // const ChildrenCampagnes = () => {
  //   switch (selectChildren) {
  //     case CAMPAGNE_FOLLOW:
  //       return <FollowCampagne />;
  //     case CAMPAGNE_NEW:
  //       return <NewCampagne />;
  //     case CAMPAGNE_CONTACT:
  //       return <Contact />;
  //     case CAMPAGNE_TEMPLATE:
  //       return <TemplateCampagne />;
  //     case CAMPAGNE_NEW_TEMPLATE:
  //       return <CreateTemplate />;
  //     default:
  //       return <ChartCampagne />;
  //   }
  // };
  const ChildrenReservations = () => {
    switch (selectChildren) {
      case RESERVATION_RECHERCHE:
        //console.log("ycycy");
        return <RechercheReservation />;
      case CAMPAGNE_FOLLOW:
        return <FollowCampagne />;
      case CAMPAGNE_NEW:
        return <NewCampagne />;
      case CAMPAGNE_CONTACT:
        return <Contact />;
      case CAMPAGNE_TEMPLATE:
        return <TemplateCampagne />;
      case CAMPAGNE_NEW_TEMPLATE:
        return <CreateTemplate />;
      // default:
      //   return <ChartCampagne />;
    }
  };
  return (
    <div
      className={classNames(
        { "pt-14": selectChildren === CAMPAGNE_DASHBOARD },
        { "pt-24 pb-8": selectChildren !== CAMPAGNE_DASHBOARD },
        "xl:px-4 pb-6 h-screen bg-white overflow-y-auto"
      )}
    >
      {ChildrenReservations()}
    </div>
  );
}
