//Conversations
export const DASHBOARD = "#";

//Conversations
export const CONVERSATION_MESSAGES = "#";
export const CONVERSATION_DASHBOARD = "#dashboard";
export const CONVERSATION_HISTORY = "#history";

//Conversations
export const CAMPAGNE_DASHBOARD = "#";
export const CAMPAGNE_FOLLOW = "#cpg-follow";
export const CAMPAGNE_NEW = "#cpg-new";
export const CAMPAGNE_CONTACT = "#cpg-contact";
export const CAMPAGNE_TEMPLATE = "#cpg-template";
export const CAMPAGNE_NEW_TEMPLATE = "#cpg-new-template";

//CRM CLIENT
export const QUOTE_REQUESTS = "#";

//FINANCE
export const FINANCES_DASHBOARD = "#";
export const FINANCES_REGISTRE_CAISSE = "#registre-caisse";
export const FINANCES_TRESORERIE = "#tresorerie";
export const FINANCES_COMMISSION_APPORTEUR = "#commission-apporteur";

//RESERVATION
export const RESERVATION_RECHERCHE = '#recherche'
export const RESERVATION_RESERVATION = "#reservations";
export const RESERVATION_TICKET = "#reservations";
export const RESERVATION_VOYAGE = "#reservations";
export const RESERVATION_INCIDENT = "#reservations";
export const RESERVATION_RECLAMATION = "#reservations";
export const RESERVATION_REMBOURSEMENT = "#reservations";

//MARKETING
export const MARKETING_MESSAGE = "#message";
