import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DashboardIcon,
  HistoryIcon,
  MessageIcon,
} from "../../components/icons";
import { NavigationInterface } from "../models";
import {
  BuildingStorefrontIcon,
  ChartBarIcon,
  ChatBubbleLeftRightIcon,
  CircleStackIcon,
  ClipboardDocumentIcon,
  ClipboardDocumentListIcon,
  CurrencyDollarIcon,
  ForwardIcon,
  IdentificationIcon,
  MegaphoneIcon,
  PhotoIcon,
  PuzzlePieceIcon,
  RectangleGroupIcon,
  RssIcon,
  ShoppingCartIcon,
  Squares2X2Icon,
  SquaresPlusIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { ArrowUturnUpIcon } from "@heroicons/react/20/solid";

import { FINANCES_REGISTRE_CAISSE, FINANCES_TRESORERIE, FINANCES_COMMISSION_APPORTEUR, RESERVATION_RECHERCHE } from "../constants/layout";

const navigationsList: NavigationInterface[] = [
  {
    name: "Dashboard",
    href: "#",
    icon: Squares2X2Icon,
    children: [
      /*{
        name: "Marketing",
        href: "#",
        icon: BuildingStorefrontIcon,
      },
      {
        name: "Commercial",
        href: "#db-commercial",
        icon: ShoppingCartIcon,
      },
      {
        name: "Operationnel",
        href: "#db-opperationnel",
        icon: PuzzlePieceIcon,
      },
      {
        name: "Satif. Client",
        href: "#db-satisfaction-client",
        icon: UserGroupIcon,
      },
      {
        name: "Chats",
        href: "#db-conversation-chat",
        icon: ChatBubbleLeftRightIcon,
      },
      {
        name: "Finances",
        href: "#db-finances",
        icon: CurrencyDollarIcon,
      },*/
    ],
    color: "gray",
  },
  // {
  //   name: "Conversations",
  //   href: "/conversations",
  //   icon: ChatBubbleLeftRightIcon,
  //   children: [
  //     {
  //       name: "Dashboard",
  //       href: "#dashboard",
  //       icon: Squares2X2Icon,
  //     },
  //     { name: "Messages", href: "#", icon: MessageIcon },

  //     // { name: "History", href: "#history", icon: HistoryIcon },
  //   ],
  //   color: "green",
  // },
  {
    name: "Réservations",
    href: "/reservations",
    icon: MegaphoneIcon,
    children: [
      {
        name: "Moteur de recherche",
        href: RESERVATION_RECHERCHE,
        icon: Squares2X2Icon,
        separator: true,
      },
      {
        name: "Réservations",
        href: "#cpg-follow",
        icon: ForwardIcon,
        separator: false,
      },
      {
        name: "Tickets",
        href: "#cpg-follow",
        icon: ForwardIcon,
        separator: false,
      },
      {
        name: "Voyages",
        href: "#cpg-follow",
        icon: ForwardIcon,
        separator: true,
      },
      {
        name: "Incidents",
        href: "#cpg-contact",
        icon: IdentificationIcon,
        separator: false,
      },
      {
        name: "Réclamations",
        href: "#cpg-contact",
        icon: IdentificationIcon,
        separator: false,
      },
      {
        name: "Remboussements",
        href: "#cpg-contact",
        icon: IdentificationIcon,
        separator: false,
      },
    ],
    color: "purple",
  },
  // {
  //   name: "CRM Clients",
  //   href: "/crm-client",
  //   icon: UsersIcon,
  //   children: [
  //     {
  //       name: "Demande de cotation",
  //       href: "#",
  //       icon: ArrowUturnUpIcon,
  //     },
  //     {
  //       name: "Instances & Demandes",
  //       href: "#crm-instances",
  //       icon: ChartBarIcon,
  //     },
  //     {
  //       name: "Contrat & Police",
  //       href: "#crm-contrat",
  //       icon: ClipboardDocumentListIcon,
  //     },
  //     {
  //       name: "Réseau de Soin",
  //       href: "#crm-reseau",
  //       icon: RssIcon,
  //     },
  //     {
  //       name: "Doc",
  //       href: "#crm-doc",
  //       icon: ClipboardDocumentIcon,
  //     },
  //   ],
  //   color: "blue",
  // },
  // {
  //   name: "Big Data",
  //   href: "/bigdata",
  //   icon: CircleStackIcon,
  //   children: [
  //     {
  //       name: "Dasboard",
  //       href: "#",
  //       icon: CircleStackIcon,
  //     },
  //     {
  //       name: "Data Analysis",
  //       href: "#data-analysis",
  //       icon: CircleStackIcon,
  //     },
  //     {
  //       name: "Data Sources",
  //       href: "#data-sources",
  //       icon: CircleStackIcon,
  //     },
  //     {
  //       name: "Data Collector",
  //       href: "#data-collector",
  //       icon: CircleStackIcon,
  //     },
  //     {
  //       name: "Data Queries",
  //       href: "#data-query",
  //       icon: CircleStackIcon,
  //     },
  //   ],
  //   color: "red",
  // },
  {
    name: "Finances",
    href: "/finance",
    icon: CurrencyDollarIcon,
    children: [
      {
        name: "Dashboard",
        href: "#",
        icon: Squares2X2Icon,
        separator: false,
      },
      {
        name: "Registre de Caisses",
        href: FINANCES_REGISTRE_CAISSE,
        icon: Squares2X2Icon,
        separator: false,
      },
      {
        name: "Trésorierie",
        href: FINANCES_TRESORERIE,
        icon: Squares2X2Icon,
        separator: false,
      },
      {
        name: "⁠Commissions Apporteurs",
        href: FINANCES_COMMISSION_APPORTEUR,
        icon: Squares2X2Icon,
        separator: false,
      },
    ],
    color: "brown",
  },
  {
    name: "Etats",
    href: "/dashboard",
    icon: Squares2X2Icon,
    children: [
      {
        name: "Marketing",
        href: "#",
        icon: BuildingStorefrontIcon,
        separator: false,
      },
      {
        name: "Commercial",
        href: "#db-commercial",
        icon: ShoppingCartIcon,
        separator: false,
      },
      {
        name: "Operationnel",
        href: "#db-opperationnel",
        icon: PuzzlePieceIcon,
        separator: false,
      },
      {
        name: "Satif. Client",
        href: "#db-satisfaction-client",
        icon: UserGroupIcon,
        separator: false,
      },
      {
        name: "Chats",
        href: "#db-conversation-chat",
        icon: ChatBubbleLeftRightIcon,
        separator: false,
      },
      {
        name: "Finances",
        href: "#db-finances",
        icon: CurrencyDollarIcon,
        separator: false,
      },
    ],
    color: "gray",
  },
  {
    name: "Marketing",
    href: "/campagnes",
    icon: MegaphoneIcon,
    children: [
      {
        name: "Dashboard",
        href: "#",
        icon: Squares2X2Icon,
        separator: true,
      },{
        name: "Conversations",
        href: "/conversations",
        icon: Squares2X2Icon,
        separator: false,
      },
      {
        name: "Leads",
        href: "#cpg-leads",
        icon: UserGroupIcon,
        separator: false,
      },
      {
        name: "Suivi des campagnes",
        href: "#cpg-follow",
        icon: ForwardIcon,
        separator: false,
      },
      {
        name: "Créer",
        href: "#cpg-new",
        icon: SquaresPlusIcon,
        separator: false,
      },
      {
        name: "Contact",
        href: "#cpg-contact",
        icon: IdentificationIcon,
        separator: false,
      },
      {
        name: "Template",
        href: "#cpg-template",
        icon: RectangleGroupIcon,
        separator: false,
      },
      {
        name: "Media",
        href: "#cpg-media",
        icon: PhotoIcon,
        separator: false,
      },
      {
        name: "CX Flows",
        href: "#cpg-cx",
        icon: PhotoIcon,
        separator: true,
      },
      {
        name: "Data Analysis",
        href: "#data-analysis",
        icon: CircleStackIcon,
        separator: false,
      },
      {
        name: "Data Sources",
        href: "#data-sources",
        icon: CircleStackIcon,
        separator: false,
      },
      {
        name: "Data Collector",
        href: "#data-collector",
        icon: CircleStackIcon,
        separator: false,
      },
      {
        name: "Data Queries",
        href: "#data-query",
        icon: CircleStackIcon,
        separator: false,
      },
    ],
    color: "purple",
  },
];

interface Props {
  children?: ReactNode;
}

export const LayoutContext = createContext({
  selectChildren: "",
  navigations: [{}],
  asideOpen: true,
  handleOpenAsude: () => {},
  handleChildren: (value: string) => {},
});

export const LayoutProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [asideOpen, setAsideOpen] = useState<boolean>(true);

  const [navigations, setNavigations] = useState<NavigationInterface[]>(
    navigationsList
  );

  const [selectChildren, setSelectChildren] = useState<string>(
    location.hash ? location.hash : "#"
  );

  useEffect(() => {}, []);

  const handleOpenAsude = () => {
    setAsideOpen(!asideOpen);
  };

  const handleChildren = (value: string) => {
    setSelectChildren(value);
    navigate(value);
  };

  const value = {
    handleOpenAsude,
    asideOpen,
    navigations,
    selectChildren,
    handleChildren,
  };
  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};
export const useLayoutContext = () => useContext(LayoutContext);
